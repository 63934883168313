
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    affTicket,
    chat
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    moment.default.locale("fr");
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      ticketSelect: {},
      valTicket: {},
      selTicket : 0,
      topChat : 95,
      hauteurChat: 124,
    });
    const tableHeader = ref([
      {
        name: "Ticket",
        key: "tic_seq",
        sortable: true,
      },
      {
        name: "Création",
        key: "tic_d_creation",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tic_c_status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "tic_knum_societe",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Statut",
        key: "tic_c_status",
        label: "tic_c_status",
      },
      {
        name: "Demandeur",
        key: "con_prenom_nom",
        label: "con_prenom_nom",
      },
    ]);

    const ExcelFormat = ref({
      "Ticket": "tic_seq",
      "Création": "tic_d_creation",  
      "Libellé du ticket": "tic_c_label",
      "Demandeur": "con_prenom_nom",
      "Statut": "tic_c_status",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const affDetail = async (data) => {
      
      state.selTicket = data;

      let myList = await getAxios("/getTicket/" + data);
      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })
      state.valTicket = myList.results;    
      state.drawer = true;
    };



    const styleImgStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "media/icons/duotune/arrows/arr085.svg";
      } else {
        return "media/icons/duotune/general/gen012.svg";
        
      }
    };

    const styleBtnStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "btn-icon-success bg-white";
      } else {
         return "btn-icon-dark bg-white svg-icon svg-icon-sm-6 ";
      }
    };



    onMounted(async () => {
      MenuComponent.reinitialization();

      let myList = await getAxios("/getTickets");
      state.initialMyList = myList.results;
      state.myList = myList.results;      
      // console.log(state.myList);

      if (state.myList.length > 0){
        if (router.currentRoute.value.params.ticid) {
          affDetail(router.currentRoute.value.params.ticid);
        }
        if (router.currentRoute.value.params.status) {
          //alert(router.currentRoute.value.params.status);
          tableFilters.value.filter(el => el.key=="tic_c_status")[0]['default'] = [router.currentRoute.value.params.status];
        }
      }

      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      refreshRouter,
      styleImgStatus,
      styleBtnStatus
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
